$default-clap-color: black;
$white: #fff;

.canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7em;
  z-index: 6;
  position: relative;
  zoom: 2;
  .totalCounter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    margin-top: -90px;
    color: grey;
    font-family: sans-serif;
    font-size: 16px;
  }
  .totalCounter.fader {
    animation: fadeIn 1400ms forwards;
  }
  .clapContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    z-index: 2;
    background: $white;
    cursor: pointer;
    .clapIcon {
      font-size: 30px;
      color: $default-clap-color;
      width: 30px;
      height: 30px;
    }
  }
  .clapContainer:hover,
  .clapContainer:focus-within {
    outline: none;
    border: 1px solid $default-clap-color;
    ~ .rippleEffectContainer {
      animation: rippleEffect 2s forwards;
    }
  }
  .rippleEffectContainer {
    width: 60px;
    height: 60px;
    background: $default-clap-color;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    z-index: 0;
  }
  .scale {
    animation: scaleAndBack 700ms forwards;
  }
  .clickCounter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50px;
    background-color: $default-clap-color;
    border-radius: 50%;
    z-index: 1;
    .counter {
      font-family: sans-serif;
      font-size: 14px;
      color: $white;
    }
  }
  .firstActive {
    animation: first-bump-in 1s forwards;
  }
  .active {
    animation: bump-in 1s forwards;
  }
  .confettiContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    position: absolute;
    // border: 1px solid gray;
    // z-index: 3;
    .triangle {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 10px solid red;
      border-bottom: 4px solid transparent;
      position: absolute;
      .square {
        width: 5px;
        height: 5px;
        background: $default-clap-color;
        position: absolute;
        left: -15px;
        top: 0;
      }
    }
    .popTop {
      animation: popTop 1s forwards;
    }
    .popTopLeft {
      animation: popTopLeft 1s forwards;
    }
    .popTopRight {
      animation: popTopRight 1s forwards;
    }
    .popBottomRight {
      animation: popBottomRight 1s forwards;
    }
    .popBottomLeft {
      animation: popBottomLeft 1s forwards;
    }
  }
}

// * * * Animations * * * //
@keyframes rippleEffect {
  0% {
    opacity: 0.7;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// * * * Pop Animations * * * //
@keyframes popTop {
  0% {
    transform: translate(0, 0) rotate(0);
    opacity: 0.4;
  }
  100% {
    transform: translate(0, -100px) rotate(0);
    opacity: 0;
  }
}
@keyframes popTopLeft {
  0% {
    transform: translate(0, 0) rotate(-55deg);
    opacity: 0.4;
  }
  100% {
    transform: translate(-100px, -50px) rotate(-55deg);
    opacity: 0;
  }
}
@keyframes popTopRight {
  0% {
    transform: translate(0, 0) rotate(55deg);
    opacity: 0.4;
  }
  100% {
    transform: translate(100px, -50px) rotate(55deg);
    opacity: 0;
  }
}
@keyframes popBottomRight {
  0% {
    transform: translate(0, 0) rotate(135deg);
    opacity: 0.4;
  }
  100% {
    transform: translate(70px, 80px) rotate(135deg);
    opacity: 0;
  }
}
@keyframes popBottomLeft {
  0% {
    transform: translate(0, 0) rotate(-135deg);
    opacity: 0.4;
  }
  100% {
    transform: translate(-70px, 80px) rotate(-135deg);
    opacity: 0;
  }
}
@keyframes first-bump-in {
  0% {
    transform: translateY(-65px);
    opacity: 1;
  }
  50% {
    transform: translateY(-80px);
    opacity: 1;
  }
  100% {
    transform: translateY(-90px);
    opacity: 0;
  }
}

@keyframes bump-in {
  0% {
    transform: translateY(-70px) scale(0.9);
    opacity: 1;
  }
  50% {
    transform: translateY(-70px) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-90px) scale(1);
    opacity: 0;
  }
}
@keyframes scaleAndBack {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
